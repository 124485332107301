<template>
  <div />
</template>

<script>
export default {
  name: 'Logout',
  created() {
    this.$store.dispatch('account/logout')
  },
}
</script>

<style scoped>

</style>
